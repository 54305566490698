.zoom-container {
    animation: zoomOutEffect 0.8s ease forwards;
  }
  
  @keyframes zoomOutEffect {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }


  @keyframes bounceEffect {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-18px);
    }
    60% {
      transform: translateY(-10px);
    }
  }
  
  
  .bounce-image {
    animation: 
      /* zoomOutEffect 0.5s ease forwards, */
      bounceEffect 2s ease 0.5s forwards; /* Starts after the zoom-out effect */
  }
  